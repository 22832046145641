
import Highcharts, { type OptionsOperatorValue } from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsGantt from "highcharts/modules/gantt"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting"
import Vue, { PropType } from "vue"
import GeneratePdf, { PdfElementType } from "@evercam/shared/mixins/generatePdf"
import {
  AnalyticsEvent,
  MediaType,
  type Project,
  type User,
} from "@evercam/api/types"
import { uploadFileToTus } from "@evercam/shared/utils"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AxiosError } from "axios"

export default Vue.extend({
  name: "CopilotChart",
  mixins: [GeneratePdf],
  props: {
    options: {
      type: Object as PropType<Highcharts.Options>,
      required: true,
    },
    redraw: {
      type: Boolean,
      default: true,
    },
    oneToOneUpdate: {
      type: Boolean,
      default: true,
    },
    project: {
      type: Object as PropType<Project>,
      default: null,
    },
    user: {
      type: Object as PropType<User>,
      default: null,
    },
  },

  data() {
    return {
      chart: null as Highcharts.Chart | null,
      chartTitle: this.options.title?.text,
      loadingFile: false,
    }
  },
  computed: {
    containerClasses() {
      return {
        "e-border-b-gray-300": !this.$vuetify.theme.isDark,
        "e-border-b-gray-600": this.$vuetify.theme.isDark,
        "e-bg-gray-900 e-text-gray-200 e-border-gray-700":
          this.$vuetify.theme.isDark,
        "e-text-gray-500 e-bg-white e-border-gray-300":
          !this.$vuetify.theme.isDark,
      }
    },
  },
  watch: {
    options: {
      handler(newValue) {
        this.updateChart(newValue)
      },
      deep: true,
    },
  },
  created() {
    this.setupHighchartsModules()
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  methods: {
    setupHighchartsModules() {
      // Initialize Highcharts modules
      HighchartsMore(Highcharts)
      HighchartsGantt(Highcharts)
      HighchartsExporting(Highcharts)
      HighchartsOfflineExporting(Highcharts)

      // Plug-in to render plot bands for the weekends
      Highcharts.addEvent(Highcharts.Axis, "foundExtremes", (e) => {
        if (!e?.target?.options?.custom?.weekendPlotBands) {
          return
        }

        const axis = e.target,
          chart = axis.chart,
          ONE_DAY_IN_MILLISENCONDS = 24 * 36e5,
          isWeekend = (t: string | number) =>
            /[06]/.test(chart.time.dateFormat("%w", t)),
          plotBands: Record<string, unknown>[] = []

        let isCurrentlyWeekend = false

        for (
          let currentTime =
            Math.floor(axis.min / ONE_DAY_IN_MILLISENCONDS) *
            ONE_DAY_IN_MILLISENCONDS;
          currentTime <=
          Math.ceil(axis.max / ONE_DAY_IN_MILLISENCONDS) *
            ONE_DAY_IN_MILLISENCONDS;
          currentTime += ONE_DAY_IN_MILLISENCONDS
        ) {
          const lastPlotBand = plotBands.at(-1)
          if (isWeekend(currentTime) && !isCurrentlyWeekend) {
            plotBands.push({
              from: currentTime,
              color: {
                pattern: {
                  path: "M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9",
                  width: 10,
                  height: 10,
                  color: "rgba(128,128,128,0.15)",
                },
              },
            })
            isCurrentlyWeekend = true
          }

          if (!isWeekend(currentTime) && isCurrentlyWeekend && lastPlotBand) {
            lastPlotBand.to = currentTime
            isCurrentlyWeekend = false
          }
        }
        axis.options.plotBands = plotBands
      })
    },
    initChart() {
      if (this.chart) {
        this.chart.destroy()
      }

      const copilotChart = this.$refs.copilotChart as HTMLElement
      const options: Highcharts.Options = {
        ...this.options,
        title: {
          text: undefined,
        },
        exporting: {
          enabled: false,
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
              },
              {
                enabled: true,
                filter: {
                  operator: ">" as OptionsOperatorValue,
                  property: "percentage",
                  value: 10,
                },
              },
            ],
          },
        },
      }
      this.chart =
        this.options.chart?.type === "gantt"
          ? Highcharts.ganttChart(copilotChart, this.options)
          : Highcharts.chart(copilotChart, options)
    },
    updateChart(newOptions: Highcharts.Options) {
      if (!this.chart) {
        this.initChart()

        return
      }
      // Update series data if it exists
      if (newOptions.series) {
        newOptions.series.forEach((series, index) => {
          if (this.chart?.series[index]) {
            this.chart.series[index].setData(
              series.data,
              this.redraw,
              this.oneToOneUpdate
            )
          }
        })
      }
      // Update other options
      this.chart.update(newOptions, this.redraw, this.oneToOneUpdate)
    },
    async getChartHtml() {
      if (!this.chart) {
        return
      }
      this.loadingFile = true
      const content = this.chart.getChartHTML()

      const pdfData = {
        "Project Name": this.project.name,
        "Created By": this.user.firstname + " " + this.user.lastname,
        "Created At": this.$moment().format("YYYY-MM-DD"),
      }
      const { blob } = await this.createPdf({
        elements: [
          {
            type: PdfElementType.Svg,
            content: content,
          },
        ],
        pdfData,
        origin: this.chartTitle || "Chart",
        fileName: `${this.chartTitle}.pdf`,
      })
      await this.uploadFile(
        blob,
        `${this.chartTitle}` ||
          `chart-${new Date().toISOString().split("T")[0]}`
      )
    },
    async uploadFile(file: File | Blob, title: string) {
      await uploadFileToTus({
        tusURL: this.$config.public.tusURL,
        file,
        title,
        onSuccess: (upload) => {
          this.saveToMediaHub(title, upload.url, "pdf")
        },
        onError: (error) => {
          console.error("Failed because: " + error)
          this.$notifications.error({
            text: this.$t("actions.upload_file_failed"),
            error,
          })
        },
      })
    },
    saveToMediaHub(title: string, fileUrl: string, fileExtension: string) {
      let date = new Date()
      const payload = {
        fromDate: date,
        toDate: date,
        title: title,
        type: MediaType.File,
        fileUrl: fileUrl,
        fileExtension: fileExtension,
      }
      this.$analytics.saveEvent(
        AnalyticsEvent.WeatherReportMediaHubUploadFile,
        { payload }
      )

      EvercamApi.mediaHub
        .cCreate(this.project.exid, payload)
        .then(() => {
          this.$notifications.success(this.$t("actions.upload_file_success"))
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status !== 401) {
            this.$notifications.error({
              text: this.$t("actions.upload_file_failed"),
              error,
            })
          }
        })
        .finally(() => {
          this.loadingFile = false
        })
    },
  },
})
